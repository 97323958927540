import React, { FC, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import FuserLoader from '../../containers/FuserPage/FuserLoader';
import BlockProps from '../../models/BlockProps';
import FuserContext from '../../context/FuserContext';
import MyToolTips from '../MyTooltip';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import { backendURL } from '../../constants/environmental';
import { testButtonStyles } from '../../constants/styles';
import { updateAtIndex, updateAtIndexRun } from '../../utils/array';
import { replacePlaceholders } from '../../utils/fuser';

// todo
// could provide tool ID and get zapier to ask for that when the user logs in

const ZapierBlock: FC<BlockProps> = ({
  isLoading,
  index,
  block,
  handleChange,
  handleTextareaFocus,
  // setIsLoading,
  // stillRunning,
  // blockStyles,
  // blocks,
  // replacePlaceholders,
  // isLoading,
  // index,
  // runnerMode,
  // handleChange,
  // block,
  // textAreaRefs,
  // setIsLoading,
  // setActivityLog,
  // tool_id
}) => {
  const {
    blockStyles,
    blocks,
    runnerMode,
    textAreaRefs,
    setBlocks,
    toolId,
    runnerIndex,
    // setActivityLog,
  } = useContext(FuserContext);

  const authHeader = useAuthHeader()();

  const [selectValue, setSelectValue] = useState('');
  const [foundHooks, setFoundHooks] = useState<string[]>([]);
  const [cleanHookNames, setCleanHookNames] = useState<string[]>([]);
  const [hooksUsed, setHooksUsed] = useState<string[]>([]);
  const [zapierButtonText, setZapierButtonText] =
    useState<string>('Send to Zapier');
  const user = useAuthUser();
  const [keysAndOutputs, setKeysAndOutputs] = useState(
    Array.from({ length: 5 }, () => ({ keyName: '', output: '' }))
  );
  const [tempSelectValue, setTempSelectValue] = useState(''); // if the tools is not saved then need this as no toolid
  const [refreshButtonText, setRefreshButtonText] = useState('Refresh Hooks');
  const [sendError, setSendError] = useState('');
  // type KeyAndOutput = {
  //   keyName: string;
  //   output: string;
  // };

  // const handleKeyChange = (index: number, type: string, value: string) => {
  //   const newKeysAndOutputs: KeyAndOutput[] = [...keysAndOutputs];
  //   (newKeysAndOutputs[index] as any)[type] = value;
  //   setKeysAndOutputs(newKeysAndOutputs);
  // };

  // const onZapierSaveClick = async (index: number) => {
  //   // not sure I need this save button?
  // };

  // const onSaveClick = () => onZapierSaveClick(index);

  useEffect(() => {
    if (foundHooks.length > 0) {
      if (hooksUsed.includes(toolId) && toolId != '') {
        // try and find assigned hook first
        setSelectValue(foundHooks[hooksUsed.indexOf(toolId)]);
      } else {
        if (tempSelectValue != '') {
          // use temp selected value (hook chosen by the user) - loses it when the tool is saved
          setSelectValue(tempSelectValue);
          console.log('selectvalue set from tempSelectValue');
        } else {
          console.log('no matching hook found');
          // not using the empty hook by default now, relying on polling to assign the correct hook ID
          /*
            // else find last unassigned hook (most recent hook), not sure if this is messy
            let emptyHookNumber = foundHooks.length-1;
            while (emptyHookNumber >= 0) {
              console.log(emptyHookNumber,hooksUsed[emptyHookNumber]);
              if (hooksUsed[emptyHookNumber] != "") {
                emptyHookNumber -= 1;
              } else break;
            }
            if (emptyHookNumber>=0) {
              setSelectValue(foundHooks[emptyHookNumber]);
              saveChosenHook(foundHooks[emptyHookNumber]);
            } else setSelectValue("");

          */
        }
      } //
    }
    setRefreshButtonText('Refresh Hooks');
    console.log('useEffect hooksUsed', selectValue);
  }, [hooksUsed]);

  useEffect(() => {
    blocks[index].data.selectValue = selectValue;
  }, [selectValue]);

  useEffect(() => {
    onHookRefresh();
  }, []);

  useEffect(() => {
    if (index == runnerIndex) {
      setBlocks(
        (runnerMode ? updateAtIndexRun : updateAtIndex)(index, block, blocks)
      );
    }
  }, [runnerIndex]);

  return (
    <FuserLoader
      name='Zapier Block'
      loading={isLoading}
    >
      <div
        className={blockStyles}
        key={index}
      >
        {runnerMode || (
          <>
            <p>
              <MyToolTips
                content="<p>Zapier</p>
<p>'key names' are names for identifying your tools output like 'social media post'<br/>
'output' is what you want to actuall send to Zapier, usually a reference to another block like '<1:output>`<br/>
Once you have filled the fields press 'Send to Zapier'. <br/>
Go to Zapier and you should see your data, build and publish your Zap to check it works<br/>
Come back here and send it again as a final check.
<p>
"
                tipID={index.toString() + '-block-types-definition'}
                datatooltipplace='left'
              />
            </p>
            <span className='hidden'>
              There was a problem connecting, here are the available hooks:
              <br />
              <button
                className={testButtonStyles + ' bg-purple-300 '}
                onClick={onHookRefresh}
              >
                {refreshButtonText}
              </button>{' '}
              (this hook is to test your tool in zapier, your users will use
              their own hook)
              <p>After refreshing hooks choose the last hook on the list:</p>
              <select
                className='text-xs bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner '
                id='block-select'
                name='selectValue'
                value={selectValue}
                onChange={onSelectChange}
              >
                {foundHooks.map((hook, index) => (
                  <option
                    value={hook}
                    key={index}
                  >
                    {cleanHookNames[index]}
                  </option>
                ))}
                {/*<option value='regex-test'>test with regex</option>*/}
              </select>
            </span>

            <div className='grid grid-cols-2 gap-4 mt-5'>
              {keysAndOutputs.map((item, tempIndex) => (
                <React.Fragment key={tempIndex}>
                  <label>
                    Key Name:
                    <br />
                    <textarea
                      name={`key_${tempIndex}`}
                      className='w-full'
                      value={blocks[index].data['key_' + tempIndex] || ''}
                      onChange={e => {
                        // handleKeyChange(index, 'keyName', e.target.value);
                        onElementChange(e);
                      }}
                      onFocus={handleTextareaFocus(tempIndex)}
                      ref={el => {
                        textAreaRefs.current[`${block.id}:${tempIndex}`] = el;
                      }}
                    />
                  </label>
                  <label>
                    Output:
                    <br />
                    <textarea
                      name={`output_${tempIndex}`}
                      className='w-full'
                      value={blocks[index].data['output_' + tempIndex] || ''}
                      onChange={e => {
                        //  handleKeyChange(index, 'output', e.target.value);
                        onElementChange(e); // Make sure onElementChange is also an event handler that takes e as an argument
                      }}
                      onFocus={handleTextareaFocus(tempIndex + 20)}
                      ref={el => {
                        textAreaRefs.current[`${block.id}:${tempIndex + 20}`] =
                          el;
                      }}
                    />
                  </label>
                </React.Fragment>
              ))}
            </div>
            <span>
              <button
                className={testButtonStyles + ' bg-purple-300'}
                onClick={onZapierSend}
                onMouseEnter={() => {
                  setZapierButtonText('Send to Zapier');
                }}
              >
                {zapierButtonText}
              </button>
              {sendError}
            </span>
          </>
        )}

        {!runnerMode || (
          <>
            {' '}
            <p>
              {' '}
              <MyToolTips
                content="<p>Zapier</p>
          The first time you use this tool:<br/>
          1) Click 'Send to Zapier', then go to zapier and make and publish your zap. Making sure `Skillfusion` is the first block in your Zap<br/>
          2) Come back here and send it again to check it all works. 
          "
                tipID={index.toString() + '-block-types-definition'}
                datatooltipplace='left'
              />
            </p>
            <span className='hidden'>
              <button
                className={testButtonStyles + ' bg-purple-300'}
                onClick={onHookRefresh}
              >
                {refreshButtonText}
              </button>
              <p>After refreshing hooks choose the last hook on the list:</p>

              <select
                className='text-xs bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner '
                id='block-select'
                name='selectValue'
                value={selectValue}
                onChange={onSelectChange}
              >
                {foundHooks.map((hook, index) => (
                  <option
                    value={hook}
                    key={index}
                  >
                    {hook}
                  </option>
                ))}
                {/*<option value='regex-test'>test with regex</option>*/}
              </select>
            </span>
            <button
              className={testButtonStyles + ' bg-purple-300'}
              onClick={onZapierSend}
              onMouseEnter={() => {
                setZapierButtonText('Send to Zapier');
              }}
            >
              {zapierButtonText}
            </button>
          </>
        )}
      </div>
    </FuserLoader>
  );

  async function onZapierSend() {
    if (toolId == '') {
      setSendError(' you must save your tool first for this to work');
      return;
    } else setSendError('');
    setZapierButtonText('sending...');
    console.log('selectValue', selectValue, selectValue == '');

    /*
      if (blocks[index].data.key_0 != '') requestBody[blocks[index].data.key_0] = replacePlaceholders(blocks[index].data.output_0,blocks);
      if (blocks[index].data.key_1 != '') requestBody[blocks[index].data.key_1] = replacePlaceholders(blocks[index].data.output_1,blocks);
      if (blocks[index].data.key_2 != '') requestBody[blocks[index].data.key_2] = replacePlaceholders(blocks[index].data.output_2,blocks);
      if (blocks[index].data.key_3 != '') requestBody[blocks[index].data.key_3] = replacePlaceholders(blocks[index].data.output_3,blocks);
      if (blocks[index].data.key_4 != '') requestBody[blocks[index].data.key_4] = replacePlaceholders(blocks[index].data.output_4,blocks);
    */

    const zapier_inputs: any = [];
    let array_length = 1;

    // saying length is 3, need to check for trim != "" too
    if (blocks[index].data.key_0 != undefined && blocks[index].data.key_0 != '')
      zapier_inputs.push(
        replacePlaceholders(blocks[index].data.output_0, blocks)
      );
    if (blocks[index].data.key_1 != undefined && blocks[index].data.key_1 != '')
      zapier_inputs.push(
        replacePlaceholders(blocks[index].data.output_1, blocks)
      );
    if (blocks[index].data.key_2 != undefined && blocks[index].data.key_2 != '')
      zapier_inputs.push(
        replacePlaceholders(blocks[index].data.output_2, blocks)
      );
    if (blocks[index].data.key_3 != undefined && blocks[index].data.key_3 != '')
      zapier_inputs.push(
        replacePlaceholders(blocks[index].data.output_3, blocks)
      );
    if (blocks[index].data.key_4 != undefined && blocks[index].data.key_4 != '')
      zapier_inputs.push(
        replacePlaceholders(blocks[index].data.output_4, blocks)
      );

    //console.log(blocks[index].data.key_2.trim(),blocks[index].data.key_2.length,blocks[index].data.key_2.trim().length);

    for (let i = 0; i < zapier_inputs.length; i++) {
      console.log(i, zapier_inputs.length);
      console.log(zapier_inputs[i].length);
      if (Array.isArray(zapier_inputs[i])) {
        array_length = zapier_inputs[i].length;
        break;
      }
    }

    //console.log(zapier_inputs);
    if (selectValue != '') {
      if (zapier_inputs.length > 0) {
        for (let i = 0; i < array_length; i++) {
          const requestBody: { [key: string]: string } = {};
          if (Array.isArray(zapier_inputs[0]))
            requestBody[blocks[index].data.key_0] = zapier_inputs[0][i];
          else requestBody[blocks[index].data.key_0] = zapier_inputs[0];
          if (zapier_inputs.length > 1)
            if (Array.isArray(zapier_inputs[1]) && zapier_inputs[1].length > 1)
              requestBody[blocks[index].data.key_1] = zapier_inputs[1][i];
            else requestBody[blocks[index].data.key_1] = zapier_inputs[1];
          if (zapier_inputs.length > 2)
            if (Array.isArray(zapier_inputs[2]) && zapier_inputs[2].length > 1)
              requestBody[blocks[index].data.key_2] = zapier_inputs[2][i];
            else requestBody[blocks[index].data.key_2] = zapier_inputs[2];
          if (zapier_inputs.length > 3)
            if (Array.isArray(zapier_inputs[3]) && zapier_inputs[3].length > 1)
              requestBody[blocks[index].data.key_3] = zapier_inputs[3][i];
            else requestBody[blocks[index].data.key_3] = zapier_inputs[3];
          if (zapier_inputs.length > 4)
            if (Array.isArray(zapier_inputs[4]) && zapier_inputs[4].length > 1)
              requestBody[blocks[index].data.key_4] = zapier_inputs[4][i];
            else requestBody[blocks[index].data.key_4] = zapier_inputs[4];

          console.log(
            Array.isArray(zapier_inputs[1]),
            '=no, content = ',
            zapier_inputs[1]
          );
          console.log(selectValue, requestBody);

          fetch(selectValue, {
            method: 'POST',
            mode: 'no-cors',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
          })
            .then(() => {
              console.log('Request was sent');
            })
            .catch(error => {
              console.error('Something went wrong:', error);
            });
        }
        setZapierButtonText('done');
      } else setZapierButtonText('no keys set');
    } else {
      //setZapierButtonText("no hook selected");
      // no hook found so send it to pending

      let pendingZap = {};
      if (Array.isArray(zapier_inputs[0]))
        pendingZap = {
          ...pendingZap,
          [blocks[index].data.key_0]: zapier_inputs[0][0],
        };
      else
        pendingZap = {
          ...pendingZap,
          [blocks[index].data.key_0]: zapier_inputs[0],
        };
      if (zapier_inputs.length > 1)
        if (Array.isArray(zapier_inputs[1]))
          pendingZap = {
            ...pendingZap,
            [blocks[index].data.key_1]: zapier_inputs[1][0],
          };
        else
          pendingZap = {
            ...pendingZap,
            [blocks[index].data.key_1]: zapier_inputs[1],
          };
      if (zapier_inputs.length > 2)
        if (Array.isArray(zapier_inputs[2]))
          pendingZap = {
            ...pendingZap,
            [blocks[index].data.key_2]: zapier_inputs[2][0],
          };
        else
          pendingZap = {
            ...pendingZap,
            [blocks[index].data.key_2]: zapier_inputs[2],
          };
      if (zapier_inputs.length > 3)
        if (Array.isArray(zapier_inputs[3]))
          pendingZap = {
            ...pendingZap,
            [blocks[index].data.key_3]: zapier_inputs[3][0],
          };
        else
          pendingZap = {
            ...pendingZap,
            [blocks[index].data.key_3]: zapier_inputs[3],
          };
      if (zapier_inputs.length > 4)
        if (Array.isArray(zapier_inputs[4]))
          pendingZap = {
            ...pendingZap,
            [blocks[index].data.key_4]: zapier_inputs[4][0],
          };
        else
          pendingZap = {
            ...pendingZap,
            [blocks[index].data.key_4]: zapier_inputs[4],
          };

      console.log(pendingZap, toolId);

      try {
        const res = await axios.post(
          `${backendURL}/zapier/pendingzap`,
          { zap: [pendingZap], toolid: toolId, username: user()?.username },
          {
            headers: {
              Authorization: authHeader,
            },
          }
        );
        console.log(res.data.message);

        if (res.data.message == 'hook already assigned') {
          // refreshes hooks and then tries again
          onHookRefresh();
          setZapierButtonText('trying to link to zapier');
          if (selectValue != '') onZapierSend();
          else
            setZapierButtonText(
              'link not found yet try again in a few seconds'
            );
        }
      } catch (error) {
        console.log('pending zap Error ' + error);
      }

      // need to check if it is an error
      setZapierButtonText('done');
    }
  }

  async function saveChosenHook(choosenHook: string) {
    // tk need to clear it out of other position? otherwise same tool will have multiple hooks
    for (let counter = 0; counter < foundHooks.length; counter++) {
      console.log(choosenHook + ' = ' + foundHooks[counter]);
      if (foundHooks[counter] == choosenHook) {
        if (toolId == '') setTempSelectValue(choosenHook);
        hooksUsed[counter] = toolId;
        console.log('found a match:', counter, hooksUsed[counter], toolId);
      } else {
        if (hooksUsed[counter] == toolId) hooksUsed[counter] = ''; // clear old choices for this tool
      }
    }

    console.log(hooksUsed, foundHooks);
    try {
      const res = await axios.post(
        `${backendURL}/user/sethooks`,
        { hooksUsed: hooksUsed },
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
      console.log(res);
    } catch (error) {
      console.log('saveChosenHook Error ' + error);
    }
  }

  async function onHookRefresh() {
    setRefreshButtonText('loading..');
    try {
      const res = await axios.post(
        `${backendURL}/user/gethooks`,
        {},
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );

      //console.log(res.data);
      //console.log("tool_id",toolId);

      const cleanHookNamesTemp: string[] = [];
      for (let i = 0; i < res.data.hooks.length; i++) {
        let buildingCleanName = '';
        if (res.data.hooksUsed[i] != '')
          buildingCleanName =
            'used - ' +
            res.data.hooks[i] +
            ' | on tool ' +
            res.data.hooksUsed[i];
        else buildingCleanName = res.data.hooks[i];
        console.log('zaptime', res.data.zaptime);
        const date = new Date(res.data.zaptime);
        const formattedDate = date.toLocaleString();
        buildingCleanName += ' | created: ' + formattedDate;
        cleanHookNamesTemp.push(buildingCleanName);
      }

      setCleanHookNames(cleanHookNamesTemp);
      setFoundHooks(res.data.hooks);
      setHooksUsed(res.data.hooksUsed);
    } catch (error) {}
    setRefreshButtonText('error!');
  }

  function onElementChange(
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) {
    handleChange(e);
    console.log('e', e.target.name);
    // console.log('blocks after change', blocks);
  }

  function onSelectChange(
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) {
    setSelectValue(e.target.value);
    saveChosenHook(e.target.value);
  }
};

export default ZapierBlock;
