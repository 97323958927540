import React, { FC, useState, useEffect } from "react";
import Avatar from "../../shared/Avatar/Avatar";
import ButtonPrimary from "../../shared/Button/ButtonPrimary";
import ButtonSecondary from "../../shared/Button/ButtonSecondary";
import { useCredit } from "../../context/CreditContext";
import { nftsImgs } from "../../constants/fakeData";
import ReactPixel from "react-facebook-pixel";
import { YoutubeAccordionInfo } from "../../containers/NftDetailPage/AccordionInfo";
import {
  useLocation,
  Link,
  useNavigate,
  useLoaderData,
  useParams,
} from "react-router-dom";
import axios from "axios";
import { useAuthHeader, useAuthUser, useSignIn } from "react-auth-kit";

import ReactGA from "react-ga4";
import ReviewsDisplay from "../../components/ReviewsDisplay";
import CommentsDisplay from "../../components/CommentsDisplay";
import QuestionAndAnswersDisplay from "./QuestionAndAnswersDisplay";
import PageGigContext from "../../context/PageGigContext";
import { FaArrowRight } from "react-icons/fa";
import { Helmet } from "react-helmet";
import PagePay from "../PagePay/PagePay";
import { MdClose } from "react-icons/md";
import { average } from "../../utils/array";
import StarRating from "../../components/StarRating";
import ImageWithFallback from "../../components/ImageWithFallback";
import { backendURL } from "../../constants/environmental";
import RecommendedToolsCarousel from "./RecommendedToolsCarousel";
import { useCookies } from "react-cookie";
import SignupModalSequence from "../../components/SignupModalSequence";

// import NcImage from '../../shared/NcImage/NcImage';

export interface FusionDetailPageProps {
  className?: string;
  isPreviewMode?: boolean;
  isLiked?: boolean;
  price?: string;
  saleTimer?: string;
}

const FusionDetailPage: FC<FusionDetailPageProps> = ({ className = "" }) => {
  const navigate = useNavigate();
  const { toolId, customUrlSlug, helmetData } = useLoaderData() as any;
  const { urlSlug } = useParams();

  useEffect(() => {
    if (customUrlSlug && urlSlug !== customUrlSlug) {
      window.location.href = `/fusion/${customUrlSlug}`;
    }
  }, []);

  const user = useAuthUser();
  const authHeader = useAuthHeader()();

  let userId = user()?.id ?? "";

  const [toolPrice, setToolPrice] = useState<string | undefined>();
  // const [hasPermission, setHasPermission] = useState(false);
  const [loadingToolDetails, setLoadingToolDetails] = useState(true);
  const [customPayPageHeading, setCustomPayPageHeading] = useState("");
  const [fusion, setFusion] = useState<any | null>(null);

  const { credit, updateCredits } = useCredit();

  const initialButtonText = "Get This Tool";
  const [buttonText, setButtonText] = useState(initialButtonText);
  const [showModal, setShowModal] = useState(false);
  const [showPayPage, setShowPayPage] = useState(false);
  const [showSignupModalSequence, setShowSignupModalSequence] = useState(false);

  const [key, setKey] = useState(0);

  const [isOwner, setIsOwner] = useState(undefined);
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [code, setCode] = useState("");
  //const [codeToSubmit,setCodeToSubmit] = useState('');

  useEffect(() => {
    const getBlocks = async () => {
      try {
        const response = await axios.get(
          `${backendURL}/blocks/public/${toolId}`,
          {
            headers: {
              authorization: authHeader,
              uid: `${toolId}`,
              userId: userId,
            },
          }
        );

        setFusion(response.data);
        const updatedToolPrice = (response.data.block.price * 10).toString();
        setToolPrice(updatedToolPrice);
        if (userId != "") {
          setIsOwner(response.data.isOwner);
          if (response.data.isOwner) {
            setButtonText("Use Tool >>");
          } else if (!response.data.purchased) {
            setButtonText(
              response.data.block.price ? "Purchase Tool" : "Add Tool"
            );
          } else {
            setButtonText("Already Purchased - Use Tool >>");
          }
        }

        let purchasesMessage =
          response.data.totalPurchases.toLocaleString() + " Users";
        if (purchasesMessage[0] == "0") {
          purchasesMessage = "Be the first to try this tool";
        }
        setPurchaseMessage(purchasesMessage);
      } catch (error) {
        console.error("Error fetching blocks:", error);
        alert("Error fetching tool");
      } finally {
        setLoadingToolDetails(false);
      }
    };

    getBlocks();
  }, [toolId]);

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  const toggleCodeInput = () => {
    if (userId == "") setUseCode("please register first");
    else setShowCodeInput(!showCodeInput);
  };

  const [useCode, setUseCode] = useState("use code");

  useEffect(() => {
    if (user) {
      userId = user()?.id || "";
    }
  }, [user]);

  const forceUpdate = () => {
    console.log("forceUpdate called");
    setKey((prevKey) => prevKey + 1);
    window.location.reload();
  };

  const HandleApplyCode = () => {
    // check code is valid, find discount amount, and update price

    const useCoupons = async () => {
      if (code == "") {
        setPurchaseMessage("");
        setPurchaseMessage("code should not be blank");
        return;
      }

      // check coupon to find out discount
      try {
        const coupons_response = await axios.post(
          `${backendURL}/blocks/checkCoupon`,
          {
            toolID: toolId,
            user: userId,
            code: code,
          },
          {
            headers: {
              Authorization: authHeader,
            },
          }
        );

        // update price
        //fusion.block.price = fusion?.block?.price * coupons_response.data.coupon.discount;
        // notify user
        setUseCode("code saved! Click 'Purchase Tool'");
        setShowCodeInput(false);
        setPurchaseMessage(""); // clearing any previous error messages
        //fusion.block.price = +(+fusion?.block?.price * +coupons_response.data.coupon.discount);
        setToolPrice(
          (fusion?.block?.price * coupons_response.data.coupon.discount)
            .toString()
            .slice(0, 5)
        );
        // check it worked
        //if (coupons_response.status == 200){
        // don't need this as returns error anyway if it doesn't work
        //}
      } catch (error) {
        setPurchaseMessage("");
        setPurchaseMessage(error.response.data.message);
        console.log("got to here ", error.response.data.message);
      }
    };

    useCoupons();
  };

  const averageStars = fusion?.ratings?.length
    ? average(fusion.ratings)
    : undefined;

  const [purchaseMessage, setPurchaseMessage] = useState<string>("");
  const [attemptingPurchase, setAttemptingPurchase] = useState<boolean>(false);
  const signIn = useSignIn();

  const [{ recommendationSourceToolId }] = useCookies([
    "recommendationSourceToolId",
    "l_id",
  ]);

  const AttemptPurchase = async () => {
    const previousButtonText = buttonText;
    try {
      if (attemptingPurchase) return;

      setAttemptingPurchase(true);

      if (buttonText.includes("Use Tool")) {
        navigate("/fuser/" + toolId);
        return;
      }

      setButtonText("attempting purchase...");

      ReactGA.event("attempted_purchase", {
        attempted_purchase_vaue: fusion?.block?.price,
        attempted_purchase_price_frequency: fusion?.block?.priceFrequency,
      });

      ReactPixel.track("Purchase", {
        currency: "USD",
        value: fusion?.block?.price,
      });

      const tryPurchase = await axios.post(
        `${backendURL}/blocks/purchase/${toolId}`,
        { userId, code, recommendationSourceToolId },
        {
          headers: {
            Authorization: authHeader,
          },
          withCredentials: true,
        }
      );

      axios
        .patch(
          `${backendURL}/recommendation/${recommendationSourceToolId}/${toolId}/incrementPurchases`,
          {},
          {
            headers: {
              Authorization: authHeader,
            },
          }
        )
        .catch(() =>
          console.log("error incrementing recommendation purchases")
        );

      const { trialAccountSignInData } = tryPurchase.data;
      if (trialAccountSignInData) {
        signInTrialUser(trialAccountSignInData);
        setPurchaseMessage(
          "You have been signed in to your trial account. All of your saved data will be transferred to the next account you register with us."
        );
      } else {
        setPurchaseMessage(tryPurchase?.data?.message ?? "");
      }

      setButtonText("Purchase successful - Use Tool >>");
      updateCredits(tryPurchase?.data?.newCredits * 10);

      return tryPurchase;
    } catch (error) {
      const errorMessage =
        error?.response?.data?.error ?? "Error purchasing tool.";
      setPurchaseMessage(errorMessage);
      setButtonText(previousButtonText);

      if (error.response.status === 401) {
        setShowModal(true);
        setShowSignupModalSequence(true);
        return;
      }

      if ([402, 403].includes(error.response.status)) {
        setShowModal(true);
        setShowPayPage(true);
        setCustomPayPageHeading(
          `${errorMessage} You can buy some more credits below.`
        );
      }
    } finally {
      setAttemptingPurchase(false);
    }
  };

  async function signInTrialUser(userData: any) {
    if (
      signIn({
        token: userData.token,
        expiresIn: userData.expirationTimeInMinutes,
        tokenType: "Bearer",
        authState: userData.authUserState,
      })
    ) {
      // get credits here and add to local storage
      try {
        const response = await axios.post(
          `${backendURL}/user/checkCredits`,
          {},
          {
            headers: {
              Authorization: `Bearer ${userData.token}`,
            },
          }
        );

        if (response.status === 200) {
          const dollarsToCredits = response.data * 10;
          updateCredits(dollarsToCredits);
        }
      } catch (error) {
        console.error(
          "Error purchasing tool - please check cookies are enabled."
        );
      }
    } else {
      throw new Error("Couldn't sign in");
    }
  }

  const renderSidebar = () => {
    let priceMessage = "";
    if (toolPrice !== undefined) {
      priceMessage += "Current Price: ";
      if (+toolPrice === 0) {
        priceMessage += "Free";
      } else {
        priceMessage += `${toolPrice} credits`;
        if (fusion?.block?.priceFrequency === "monthly") {
          priceMessage += " per month";
        }
      }
      priceMessage += ' to purchase, runs on credits'
    } else {
      priceMessage += "Loading price...";
    }
    return (
      <div className="pagegig-sidebar divide-y divide-neutral-100 dark:divide-neutral-800 border-2 border-neutral-200 rounded-xl p-8">
        <span className="text-lg font-bold">{priceMessage}</span>

        <div className="mt-8 flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-3">
          <ButtonPrimary
            onClick={AttemptPurchase}
            className={"flex-1"}
            disabled={attemptingPurchase || loadingToolDetails}
          >
            <FaArrowRight />

            <span className="ml-2.5">
              {loadingToolDetails ? "Loading..." : buttonText}
            </span>
          </ButtonPrimary>
          {fusion?.price && (
            <ButtonSecondary
              onClick={() => {
                navigate(`/mymessages/${user()?.id}`);
              }}
              className="flex-1"
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M8.57007 15.27L15.11 8.72998"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.98001 10.3699C9.65932 10.3699 10.21 9.81923 10.21 9.13992C10.21 8.46061 9.65932 7.90991 8.98001 7.90991C8.3007 7.90991 7.75 8.46061 7.75 9.13992C7.75 9.81923 8.3007 10.3699 8.98001 10.3699Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15.52 16.0899C16.1993 16.0899 16.75 15.5392 16.75 14.8599C16.75 14.1806 16.1993 13.6299 15.52 13.6299C14.8407 13.6299 14.29 14.1806 14.29 14.8599C14.29 15.5392 14.8407 16.0899 15.52 16.0899Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span className="ml-2.5">Purchase One Time</span>
            </ButtonSecondary>
          )}
        </div>

        {fusion?.block?.price > 0 && (
          <p style={{ clear: "both", textAlign: "right", paddingTop: "5px" }}>
            <a
              style={{
                textDecoration: "underline",
                color: "grey",
                cursor: "pointer",
              }}
              onClick={toggleCodeInput}
            >
              {useCode}
            </a>
          </p>
        )}

        {showCodeInput && (
          <div style={{ textAlign: "right" }}>
            <input type="text" value={code} onChange={handleCodeChange} />
            <br />
            <button onClick={HandleApplyCode} className="text-sky-700 b">
              Apply
            </button>
          </div>
        )}

        <p id="result-message"></p>

        <br />

        <p>{purchaseMessage}</p>
      </div>
    );
  };

  const pageGigContextValues = {
    isOwner,
  };

  // const mainDivStyle = 'col-span-2 space-y-8 lg:space-y-10';

  function htmlToMetaDescription(htmlString: string) {
    // Remove all HTML tags
    let text = htmlString.replace(/<[^>]*>/g, "");

    // Decode HTML entities
    const textArea = document.createElement("textarea");
    textArea.innerHTML = text;
    text = textArea.value;

    // Trim the string to the desired length
    const maxLength = 160;
    if (text.length > maxLength) {
      text = text.slice(0, text.lastIndexOf(" ", maxLength)) + "...";
    }

    return text;
  }

  const closeModal = () => {
    setShowModal(false);

    if (showSignupModalSequence) {
      setShowSignupModalSequence(false);
      setButtonText("Purchase Tool");
      setPurchaseMessage("");
      // refresh the page to get it to load the token
      forceUpdate();
    }
    if (showPayPage) {
      setShowPayPage(false);
    }
  };

  const toolName = helmetData?.name ?? fusion?.block?.name;

  const metaContent =
    helmetData?.customMeta ||
    fusion?.block?.customMeta ||
    htmlToMetaDescription(fusion?.block?.description ?? "");

  const pageTitle =
    helmetData?.customPageTitle ||
    `${toolName ? `${toolName} - ` : ""}Tool Details - Skillfusion`;

  return (
    <PageGigContext.Provider value={pageGigContextValues} key={key}>
      <div
        className={`nc-NftDetailPage ${className}`}
        data-nc-id="NftDetailPage"
      >
        <Helmet>
          <title>{pageTitle}</title>
          <link
            rel="canonical"
            href={`https://skillfusion.ai/fusion/${customUrlSlug || urlSlug}`}
          />
          <meta name="description" content={metaContent} />
        </Helmet>
        <main className="container mt-11 flex ">
          <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-10 md:gap-14">
            <div className="col-span-2 space-y-8">
              {loadingToolDetails ? (
                "Loading tool details..."
              ) : (
                <>
                  <div className="flex gap-4 items-center">
                    <ImageWithFallback
                      src={fusion?.block.coverPhoto}
                      className="object-cover w-20 h-20 rounded-lg border-black border-2"
                      containerClassName="relative flex-shrink-0 h-20"
                    />
                    <h1 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
                      {fusion?.block?.name}
                    </h1>
                  </div>

                  {averageStars && fusion?.ratings?.length && (
                    <div className="flex gap-2 items-start">
                      <StarRating ratingOutOfFive={averageStars} />
                      <span className="text-lg align-top">
                        {fusion.ratings.length} Reviews
                      </span>
                    </div>
                  )}
                  {fusion?.block?.description && (
                    <YoutubeAccordionInfo
                      description={fusion.block.description}
                    />
                  )}
                  {fusion?.block?.furtherInfo && (
                    <YoutubeAccordionInfo
                      description={fusion.block.furtherInfo}
                      customTitle="Further info"
                      defaultOpen={false}
                      scroll
                    />
                  )}
                  <div className="pb-9 space-y-5">
                    <div className="flex flex-col sm:flex-row sm:items-center space-y-4 sm:space-y-0 sm:space-x-8 text-sm">
                      <div className="flex items-center ">
                        <Avatar
                          imageUrl={`${
                            fusion?.author?.profileImage || nftsImgs[3]
                          }`}
                          sizeClass="h-9 w-9"
                          radius="rounded-full"
                        />
                        <span className="ml-2.5 text-neutral-500 dark:text-neutral-400 flex flex-col">
                          <span className="text-sm">Creator</span>
                          <span className="text-neutral-900 dark:text-neutral-200 font-medium flex items-center">
                            <span className="">
                              {loadingToolDetails ? (
                                "Loading creator details..."
                              ) : (
                                <Link to={`/profile/${fusion?.author._id}`}>
                                  {fusion?.author?.name ?? "username"}
                                </Link>
                              )}
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div
              style={{ zIndex: 3 }}
              className="sticky rounded-b-lg top-0 lg:top-4 col-span-2 lg:col-span-1 w-full bg-white pt-10 lg:pt-0 xl:pl-10 border-t-2 border-neutral-200 dark:border-neutral-700 lg:border-t-0"
            >
              {renderSidebar()}
            </div>

            <div className="col-span-2 space-y-8 lg:space-y-10" id="mainDiv">
              {toolId && <RecommendedToolsCarousel blockId={toolId} />}

              <ReviewsDisplay toolId={toolId} />

              <QuestionAndAnswersDisplay toolId={toolId} />

              <CommentsDisplay
                targetType="tool-details-page"
                targetId={toolId}
                isOwner={isOwner}
              />
            </div>
          </div>
        </main>
        {showModal && (
          <div className="share-modal fixed inset-0 flex justify-center">
            <div className="absolute inset-0 bg-black opacity-50 z-10"></div>
            <div className="relative z-20">
              <div className="m-auto flex items-center justify-center p-4">
                <div className="bg-white p-4 rounded shadow-md m-auto max-w-[90vw] z-20">
                  <div className="flex flex-col gap-4">
                    <div>
                      <button
                        className="sticky left-0 top-0"
                        onClick={closeModal}
                      >
                        <MdClose />
                      </button>
                    </div>
                    <div
                      className={`${
                        showPayPage ? "p-x-8" : "flex p-8"
                      } justify-between gap-4 overflow-y-auto max-w-full max-h-[87vh]`}
                    >
                      {showSignupModalSequence && <SignupModalSequence />}
                      {showPayPage && (
                        <PagePay
                          customHeading={customPayPageHeading}
                          inModal={true}
                          onPayment={() => {
                            setShowPayPage(false);
                            setShowModal(false);
                            setPurchaseMessage("");
                          }}
                        />
                      )}
                    </div>
                    <div className="flex gap-2 items-center"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </PageGigContext.Provider>
  );
};

export default FusionDetailPage;
