import axios from "axios";
import React, {
  FC,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useAuthHeader } from "react-auth-kit";
import { MdClose } from "react-icons/md";
import { getBrowser, getOperatingSystem } from "../utils/userDeviceInfo";
import { backendURL } from "../constants/environmental";
import { BrowserSettingsContext } from "../context/BrowserSettingsContext";
import FuserContext from "../context/FuserContext";

const ReportIssueDialog: FC<any> = () => {
  const dialogRef = useRef<HTMLDialogElement | null>(null);
  const { runnerModeBlocksData, setShowReportIssueDialog, toolId, runnerMode } =
    useContext(FuserContext);
  const { localStorageAvailable } = useContext(BrowserSettingsContext);

  const [issueInfo, setIssueInfo] = useState<string>("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [submissionStatusMessage, setSubmissionStatusMessage] =
    useState<string>("");

  const authHeader = useAuthHeader()();

  useLayoutEffect(() => {
    dialogRef.current?.showModal();
  }, [dialogRef]);

  return (
    <dialog
      className="backdrop:bg-gray-400 backdrop:bg-opacity-50"
      ref={dialogRef}
    >
      <div className="flex justify-between gap-4 mb-4">
        <h1 className="text-lg font-bold">Report Issue</h1>
        <button
          onClick={() => {
            dialogRef.current?.close();

            // reset state so that additional reports can be sent
            setShowReportIssueDialog(false);
            setFormSubmitted(false);
            setIssueInfo("");
          }}
        >
          <MdClose />
        </button>
      </div>

      {formSubmitted ? (
        <p>Thank you for your help!</p>
      ) : (
        <form
          className="flex flex-col gap-2 items-center"
          onSubmit={onReportIssueFormSubmit}
        >
          <label className="w-full">
            <p>
              Please describe the issue here and provide any additional info on
              what led up to it.
            </p>
            <textarea
              autoFocus
              className="resize rounded-md w-full"
              rows={6}
              value={issueInfo}
              onChange={({ target: { value } }) => setIssueInfo(value)}
            />
          </label>
          <p>
            {
              "Disclaimer: When the report is sent, details of errors that occurred on this website as well as the tool's inputs and outputs will be sent to our database."
            }
          </p>
          {submissionStatusMessage && <p>{submissionStatusMessage}</p>}{" "}
          <button
            type="submit"
            className="justify-self-center md:justify-self-end bg-blue-100 border-2 p-2 rounded-lg hover:bg-white border border-black border-1 rounded-lg p-1 px-2"
          >
            Send report
          </button>
        </form>
      )}
    </dialog>
  );

  function onReportIssueFormSubmit(event: any) {
    event.preventDefault();

    setSubmissionStatusMessage("Sending report...");

    const requestBody: any = {
      tool: toolId || undefined,
      info: issueInfo,
      browser: getBrowser(),
      operatingSystem: getOperatingSystem(),
    };

    if (runnerMode) {
      requestBody.runnerModeData = runnerModeBlocksData;
    }

    if (localStorageAvailable) {
      const errorLogsString = localStorage.getItem("errorLogs");
      if (errorLogsString) {
        requestBody.errorLogs = JSON.parse(errorLogsString);
      }

      const consoleLogsString = localStorage.getItem("consoleLogs");
      if (consoleLogsString) {
        requestBody.consoleLogs = JSON.parse(consoleLogsString);
      }
    }

    axios
      .post(`${backendURL}/report-issue`, requestBody, {
        headers: {
          Authorization: authHeader,
        },
      })
      .then(() => {
        setFormSubmitted(true);
      })
      .catch((error) => {
        console.log(error);
        setSubmissionStatusMessage("There was an error submitting your report");
      });
  }
};

export default ReportIssueDialog;
