import React, { useEffect, useRef } from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/solid';
import { useNavigate } from 'react-router-dom';
import DisclosurePanelWithRef from '../../components/DisclosurePanelWithRef';
import { encodeHtmlEntities } from '../../utils/string';

type AccordionInfoProps = {
  description: string;
  customTitle?: string;
  defaultOpen?: boolean;
  scroll?: boolean;
};

export default function AccordionInfo({ description }: AccordionInfoProps) {
  return (
    <div className='w-full rounded-2xl'>
      <Disclosure defaultOpen>
        {({ open }) => (
          <>
            <Disclosure.Button className='flex justify-between w-full px-4 py-2 font-medium text-left bg-neutral-100 dark:bg-neutral-700 dark:hover:bg-neutral-500 rounded-lg hover:bg-neutral-200 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500 focus-visible:ring-opacity-75'>
              <span>Description</span>
              <ChevronUpIcon
                className={`${
                  open ? 'transform rotate-180' : ''
                } w-5 h-5 text-neutral-500`}
              />
            </Disclosure.Button>
            <Disclosure.Panel
              className='px-4 pt-4 pb-2 text-neutral-500 text-lg dark:text-neutral-400'
              as='div'
            >
              {description.split('\n').map((line, index) => (
                <div key={index}>
                  {line}
                  <br />
                </div>
              ))}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      {/* <Disclosure defaultOpen as="div" className="mt-5 md:mt-8">
        {({ open }) => (
          <>
            <Disclosure.Button className="flex justify-between w-full px-4 py-2 font-medium text-left bg-neutral-100 dark:bg-neutral-700 dark:hover:bg-neutral-500 rounded-lg hover:bg-neutral-200 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500 focus-visible:ring-opacity-75">
              <span>Tutorials</span>
              <ChevronUpIcon
                className={`${
                  open ? "transform rotate-180" : ""
                } w-5 h-5 text-neutral-500`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="px-4 pt-4 pb-2 flex flex-col text-xs text-neutral-500 dark:text-neutral-400 overflow-hidden">
                <div className="container rounded-3xl bg-gray-200 py-4">
                  We need to map out any tutorials header
                  We can do this once we create our first skill, and were able to map out the appropriate information for it like a product card
                </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure> */}
    </div>
  );
}

const p = (innerHTML: string) => `<p class="mb-3.5">${innerHTML}</p>`;
const escapeP = (innerHTML: string) => `</p>${innerHTML}<p>`;
const pInnerHTMLRegex = /<p>(.*?)<\/p>/gs;
const pOnlyContainingWhitespaceRegex = /<p>\s*<\/p>/gs;

function findLastIndex(array: string[], target: string) {
  for (let i = array.length - 1; i >= 0; i--) {
    if (array[i] === target) {
      return i;
    }
  }
  return -1;
}

export function YoutubeAccordionInfo({
  description,
  customTitle,
  defaultOpen,
  scroll,
}: AccordionInfoProps) {
  // split at every top level opening/closing tag, wrap unwrapped content in inline <p>
  const allowedInnerTags = ['b', 'i', 'u', 'a', 'em', 'strong', 'br'];
  const allowedTopLevelTags = ['h2', 'h3', 'ul', 'p', 'li'];
  const allowedTags = [...allowedTopLevelTags, ...allowedInnerTags];

  const [allowedTagsRegex, allowedTopLevelTagsRegex, allowedInnerTagsRegex] = [
    allowedTags,
    allowedTopLevelTags,
    allowedInnerTags,
  ].map(
    tagArray =>
      new RegExp(`(<\\/?(?:${tagArray.join('|')})(?:\\s+[^>]*)?>)`, 'gi')
  );

  const encodeInnerContent = (content: string) =>
    content
      .split(allowedInnerTagsRegex)
      .filter((segment: string) => segment !== '')
      .map((innerSegment: string) =>
        allowedInnerTagsRegex.test(innerSegment)
          ? innerSegment
          : encodeHtmlEntities(innerSegment)
      )
      .join('');

  const tagClass: Record<string, string> = {
    h2: 'text-xl font-bold',
    h3: 'text-lg font-bold',
    ul: 'list-disc list-inside',
    li: '',
    b: '',
    i: '',
    u: '',
    a: 'text-decoration-line underline text-blue-700 active:text-red-700 visited:text-purple-700',
    p: '',
    em: '',
    strong: '',
    br: '',
  };
  const isOpeningTag = (tag: string) => tag[1] !== '/';

  const imageUrlRegex =
    /\b(http[s]:\/\/)?(www\.)?(blog\.)?skillfusion\.ai\S*\.(jpg|jpeg|png|gif|bmp|svg)\b/gi;

  const videoUrlRegex =
    /(http[s]:\/\/)?(www\.)?(youtube\.com\/watch\?|youtu\.be\/)\S*/gm;

  // splits the description at the boundary of each allowed HTML tag
  const descriptionSegments = description
    // wrap any links to skillfusion that aren't hrefs or srcs in a tags
    .replace(
      /((?:href|src)\s*=\s*['"]\s*)?((?:http[s]?:\/\/)?(?:www\.)?(?:blog\.)?skillfusion\.ai[A-Za-z0-9\-._~!$&'()*+,;=:@/?#[\]%]*)/gi,
      (match, prefix, url) => {
        if (prefix) return match;
        else {
          return ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'].some(
            imageFileExtension => url.endsWith(imageFileExtension)
          )
            ? url
            : `<a href="${url}">${url}</a>`;
        }
      }
    )
    .split(allowedTopLevelTagsRegex)
    .filter(segment => segment !== '');

  // console.log(descriptionSegments);
  let descriptionHTML = '';

  // this keeps track of the tag nesting while looping over segments
  const currentTagTypeStack: string[] = [];

  for (const segment of descriptionSegments) {
    // console.log(segment, currentTagTypeStack);
    if (allowedTopLevelTagsRegex.test(segment)) {
      if (isOpeningTag(segment)) {
        // console.log(segment);
        currentTagTypeStack.push(
          segment.slice(1).split(' ')[0].replace('>', '')
        );
      } else if (segment === `</${currentTagTypeStack.at(-1)}>`)
        currentTagTypeStack.pop();

      descriptionHTML += segment;
    } else if (currentTagTypeStack.length === 0) {
      // this case is for top level text, i.e. not contained in an allowedTopLevelTag
      descriptionHTML += segment
        .split('\n\n')
        .map(line =>
          p(
            line
              .replace(videoUrlRegex, url =>
                // escape out of the current paragraph to insert iframe & img elements
                escapeP(
                  `<iframe class="w-full max-w-560" height="315" src="${url.replace(
                    /(youtube\.com\/watch\?v=|youtu\.be\/)/,
                    'youtube.com/embed/'
                  )}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`
                )
              )
              .replace(imageUrlRegex, url =>
                escapeP(`<img src="${url}"></img>`)
              )
              //.replace('/\n\n|\r\r/g', '<br />')
              .replace(/\n|\r/g, '<br />')
          )
            .replace(pInnerHTMLRegex, (_, innerText) => p(innerText))
            .replace(pOnlyContainingWhitespaceRegex, (_, spaces) =>
              // if the tag is empty, the value of spaces is an offset number
              typeof spaces === 'string' ? spaces : ''
            )
        )
        .join('')
        .replace(pInnerHTMLRegex, (_, innerText) =>
          p(encodeInnerContent(innerText))
        );
    } else {
      // this case is for content inside top level tags that isn't a top level tag
      descriptionHTML += encodeInnerContent(
        // wrap any links to skillfusion that aren't images in a tags
        segment
      );
    }
  }

  descriptionHTML = descriptionHTML
    // make sure the site starts with https://(blog.)?skillfusion.ai incase someone tries using the javascript protocol
    .replace(
      /(<a\s+[^>]*href\s*=\s*['"]\s*)(\S+)(\s*['"][^>]*>)/g,
      (_, tagStart, url, tagEnd) => {
        return `${tagStart}https://${
          url.includes('blog.') ? 'blog.' : ''
        }skillfusion.ai/${url.replace(
          /^(https?:\/\/)?(www\.)?(blog\.)?skillfusion\.ai(\/|$)/,
          ''
        )}${tagEnd}`;
      }
    )

    // remove /.. to prevent path traversal attacks
    .replace(/\/\.\./g, '')
    // add custom classes defined in the tagClass object
    .replace(
      /(<(?:h2|h3|ul|li|b|i|u|a|p|br))([\s>])/g,
      (match, tagStart, nextCharacter) => {
        const className = tagClass[tagStart.slice(1)];
        return className
          ? `${tagStart} class='${className}' ${nextCharacter}`
          : match;
      }
    );

  const navigate = useNavigate();
  const descriptionElement: any = useRef();

  useEffect(() => {
    if (!descriptionElement.current) return;
    const handleClick = (event: any) => {
      const anchor = event.target.closest('a'); // Find the closest anchor tag
      if (anchor) {
        const url = new URL(
          anchor.getAttribute('href'),
          window.location.origin
        );
        if (url.origin === 'https://skillfusion.ai') {
          event.preventDefault();
          navigate({ pathname: url.pathname });
        }
      }
    };

    descriptionElement?.current?.addEventListener('click', handleClick);
    return () =>
      descriptionElement?.current?.removeEventListener('click', handleClick);
  }, [navigate]);

  // function embedYouTubeLinksAndImages(text: string) {
  //   if (!text) return <span dangerouslySetInnerHTML={{ __html: '<br/>' }} />;

  //   const imageUrlRegex =
  //     /\b(http[s]:\/\/)?(www\.)?(blog\.)?skillfusion\.ai\S*\.(jpg|jpeg|png|gif|bmp|svg)\b/gi;

  //   const videoUrlRegex =
  //     /(http[s]:\/\/)?(www\.)?(youtube\.com\/watch\?|youtu\.be\/)\S*/gm;

  //   const html = text
  //     .replace(videoUrlRegex, url => {
  //       const embedURL = url.replace(
  //         /(youtube\.com\/watch\?v=|youtu\.be\/)/,
  //         'youtube.com/embed/'
  //       );
  //       return `<iframe class="w-full max-w-560" height="315" src="${embedURL}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`;
  //     })
  //     .replace(imageUrlRegex, url => `<img src="${url}"></img>`);

  //   return <div dangerouslySetInnerHTML={{ __html: html }} />;

  //   // if (videoUrlRegex.test(text)) {
  //   //   const embedURL = text.replace(
  //   //     /(youtube\.com\/watch\?v=|youtu\.be\/)/,
  //   //     'youtube.com/embed/'
  //   //   );
  //   //   const iframeString = `<iframe dlass="w-full max-w-560" height="315" src="${embedURL}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`;
  //   //   return <div dangerouslySetInnerHTML={{ __html: iframeString }} />;
  //   // } else {
  //   //   return <>{text}</>;
  //   // }
  // }

  //const updatedDescription = embedYouTubeLinks(description);

  return (
    <div className='w-full rounded-2xl'>
      <Disclosure defaultOpen={defaultOpen ?? true}>
        {({ open }) => (
          <>
            {customTitle && (
              <Disclosure.Button className='flex justify-between w-full px-4 py-2 font-medium text-left bg-neutral-100 dark:bg-neutral-700 dark:hover:bg-neutral-500 rounded-lg hover:bg-neutral-200 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500 focus-visible:ring-opacity-75'>
                <span>{customTitle ?? 'Description'}</span>
                <ChevronUpIcon
                  className={`${
                    open ? 'transform rotate-180' : ''
                  } w-5 h-5 text-neutral-500`}
                />
              </Disclosure.Button>
            )}

            <DisclosurePanelWithRef
              className={`pt-4${scroll ? ' overflow-y-auto max-h-[50vh]' : ''}`}
              as='div'
              unmount={false}
              html={descriptionHTML}
              ref={descriptionElement}
            ></DisclosurePanelWithRef>
          </>
        )}
      </Disclosure>
    </div>
  );
}
