import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import React, { useRef } from 'react';
import axios from 'axios';
import { useEffect, useContext } from 'react';
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from '@paypal/react-paypal-js';
import { PagePayContext } from './PagePay';
import { useCredit } from '../../context/CreditContext';
import { useAuthHeader } from 'react-auth-kit';
import { backendURL, cookieDomain } from '../../constants/environmental';
import { useCookies } from 'react-cookie';
import { daysToMilliseconds, futureDateAfterMilliseconds } from '../../utils/date';

const { REACT_APP_PAYPAL_CLIENT_ID, REACT_APP_PAYPAL_SANDBOX_CLIENT_ID } =
  process.env;

let fundingSource: string | undefined;

function ButtonWrapper({
  currency,
  amountOfCredits,
}: {
  currency: string;
  amountOfCredits: number;
}) {
  const authHeader = useAuthHeader()();

  // console.log((window as any).gtag);

  const navigate = useNavigate();
  // get the state for the sdk script and the dispatch method
  const [{ options, isPending, isRejected, isResolved }, dispatchScript] =
    usePayPalScriptReducer();

  const paypalButtonsContainerRef = useRef<any>();
  const [cookies, setCookie, removeCookie] = useCookies([
    "_auth_state", 
    'accountCreatedWithNoFreeCreditsAndNeverBoughtCredits'
  ]);

  useEffect(() => {
    if (!isPending) {
      setTimeout(() => {
        if (paypalButtonsContainerRef.current) {
          // const innerHtmlStringIsEmptyDiv =
          //   paypalButtonsContainerRef.current.innerHTML ===
          //   '<div class=""></div>';
          const innerContent = paypalButtonsContainerRef.current.children;
          if (!innerContent?.[0]?.children?.length) {
            setPaypalButtonsLoadRetries((retries: number) =>
              Math.min(0, retries - 1)
            );
            console.log('Retrying PayPal buttons load');
          } else {
            console.log('Successfully loaded PayPal buttons');
          }
        }
      }, 500);
    }
  }, [isResolved]);

  // console.log({ isRejected, isPending, isResolved });

  const {
    billingFrequency,
    userId,
    inModal,
    onPayment,
    setPaypalButtonsStatusMessage,
    setCreditSubscriptionsStatusMessage,
    exchangeRates,
    SANDBOX_MODE: sandboxMode,
    paypalButtonsLoadRetries,
    setPaypalButtonsLoadRetries,
    // paypalButtonsStatusMessage,
  } = useContext(PagePayContext);

  const { credit, updateCredits } = useCredit();

  useEffect(() => {
    dispatchScript({
      type: 'resetOptions',
      value: {
        ...options,
        currency,
        intent: billingFrequency === 'one-time' ? 'capture' : 'subscription',
        vault: billingFrequency !== 'one-time',
      },
    });
  }, [currency, billingFrequency, paypalButtonsLoadRetries]);

  const createOrder = async () => {
    // updatePageSession({ purchaseAttemptType: 'one-time' });

    ReactGA.event('paypal_checkout_button_clicked', {
      amountOfCredits,
      billingFrequency,
      currency,
      fundingSource,
    });

    try {
      const response = await axios.post(
        `${backendURL}/paypal/orders/${userId}`,
        { amountOfCredits, currency, sandboxMode },
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );

      // console.log('createorder response:', response);

      if (response.data.id) return response.data.id;
      else {
        const errorDetail = response.data?.details?.[0];
        const errorMessage = errorDetail
          ? `${errorDetail.issue} ${errorDetail.description} (${response.data.debug_id})`
          : JSON.stringify(response.data);

        throw new Error(errorMessage);
      }
    } catch (error) {
      const castError = error as { response?: { data?: { message: string } } };
      console.log(error);
      setCreditSubscriptionsStatusMessage('');
      setPaypalButtonsStatusMessage(
        castError?.response?.data?.message ?? 'Failed to create order'
      );
    }
  };

  const onOrderApprove = async (data: any, actions: any) => {
    try {
      const response = await axios.post(
        `${backendURL}/paypal/orders/${data.orderID}/${userId}/capture`,
        { sandboxMode },
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );

      //console.log(response);
      if (response.status === 201) {
        const { creditsBought, dollarsPaid, transactionId } = response.data;
        const newCreditAmount = credit + creditsBought;
        updateCredits(newCreditAmount);
        setPaypalButtonsStatusMessage('Transaction successful! Redirecting...');
    
        removeCookie("accountCreatedWithNoFreeCreditsAndNeverBoughtCredits", {
          domain: cookieDomain,
          path: '/'
        });
        // console.log(
        //   'dollarsPaid:',
        //   dollarsPaid,
        //   'type:',
        //   typeof dollarsPaid,
        //   'transaction_id:',
        //   userId + Date.now(),
        //   'type:',
        //   typeof (userId + Date.now())
        // );
        // tracking paypal order
        if (!sandboxMode) {
          if ((window as any).gtag) {
            (window as any).gtag('event', 'conversion', {
              send_to: 'AW-11370545127/0IZzCO6F-74ZEOf_8q0q',
              value: dollarsPaid * exchangeRates.GBP,
              currency: 'GBP',
              transaction_id: transactionId,
            });
          }
          const result = ReactGA.event('purchase', {
            currency: 'USD',
            value: dollarsPaid,
            transaction_id: userId + Date.now(),
            shipping: 0,
            tax: 0,
            items: [
              {
                item_id: 'Paypalcredit',
                item_name: 'PayPal Credit',
                quantity: 1,
                price: dollarsPaid,
                frequency: 'one_time',
              },
            ],
          });
          ReactPixel.track('Purchase', {
            currency: 'USD',
            value: dollarsPaid,
          });
        }
        // updatePageSession({ purchaseType: 'one-time' });


        if (inModal) onPayment();
        else {
          navigate(`/page-search/`, {
            state: {
              creditsBought,
              newCreditAmount,
              transactionType: 'one-time',
            },
          });
        }
      }
      // if (response.data?.purchase_units) {
      //   const transaction =
      //     response.data.purchase_units[0].payments.captures[0] ||
      //     response.data.purchase_units[0].payments.authorizations[0];
      //   console.log('order approve response:', response);
      //   setPaypalButtonsStatusMessage('Transaction successful!');
      //   // console.log(
      //   //   'Capture result',
      //   //   response.data,
      //   //   JSON.stringify(response.data, null, 2)
      //   // );
      // }
    } catch (error) {
      const castError = error as { response?: { data?: { message: string } } };
      console.log(error);
      setCreditSubscriptionsStatusMessage('');
      setPaypalButtonsStatusMessage(
        castError?.response?.data?.message ?? 'Failed to approve transaction'
      );
    }
  };

  const createSubscription = async (data: any, actions: any) => {
    // console.log('createSubscription; data:', data, 'actions', actions);
    // updatePageSession({ purchaseAttemptType: 'monthly' });

    ReactGA.event('paypal_checkout_button_clicked', {
      amountOfCredits,
      billingFrequency,
      currency,
      fundingSource,
    });

    try {
      const response: any = await axios.post(
        `${backendURL}/paypal/subscriptions`,
        {
          billingFrequency,
          currency,
          amountOfCredits,
          sandboxMode,
        },
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );

      const planId = response.data.id;

      // console.log('planId:', planId);

      return actions.subscription.create({
        plan_id: planId,
        application_context: {
          brand_name: 'Skillfusion.ai',
          shipping_preference: 'NO_SHIPPING',
        },
      });
    } catch (error) {
      console.log(error);
      const errorObject = error as any;
      setCreditSubscriptionsStatusMessage('');
      return setPaypalButtonsStatusMessage(
        errorObject?.response?.data?.message ??
          'There was an error creating your subscription.'
      );
    }
  };

  const onSubscriptionApprove = async (data: any, actions: any) => {
    // console.log('onSubscriptionApprove; data:', data, 'actions', actions);

    try {
      const response = await axios.post(
        `${backendURL}/paypal/subscriptions/${data.subscriptionID}/approve`,
        { sandboxMode },
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );

      // console.log('subscription approved:', response);
      const { creditsBought, bonusCredits, dollarsPaid, transactionId } =
        response.data;
      const newCreditAmount = credit + creditsBought + bonusCredits;
      updateCredits(newCreditAmount);
      setPaypalButtonsStatusMessage('Transaction successful! Redirecting...');

      removeCookie("accountCreatedWithNoFreeCreditsAndNeverBoughtCredits", {
        domain: cookieDomain,
        path: '/'
      });

      // tracking paypal order
      if (!sandboxMode) {
        if ((window as any).gtag) {
          (window as any).gtag('event', 'conversion', {
            send_to: 'AW-11370545127/0IZzCO6F-74ZEOf_8q0q',
            value: dollarsPaid * exchangeRates.GBP,
            currency: 'GBP',
            transaction_id: transactionId,
          });
        }
        ReactGA.event('purchase', {
          currency: 'USD',
          value: dollarsPaid,
          transaction_id: userId + Date.now(),
          shipping: 0,
          tax: 0,
          items: [
            {
              item_id: 'Paypalcredit',
              item_name: 'PayPal Credit',
              quantity: 1,
              price: dollarsPaid,
              frequency: 'monthly',
            },
          ],
        });
        ReactPixel.track('Purchase', {
          currency: 'USD',
          value: dollarsPaid,
        });
      }
      // updatePageSession({ purchaseType: 'monthly' });

      if (inModal) onPayment();
      else {
        navigate(`/page-search/`, {
          state: {
            creditsBought,
            bonusCredits,
            newCreditAmount,
            transactionType: 'subscription',
            billingFrequency,
            dateOfNextPaymentString: response?.data?.dateOfNextPayment,
          },
        });
      }
      return setPaypalButtonsStatusMessage(
        'Subscription successfully activated!'
      );
    } catch (error) {
      const castError = error as { response?: { data?: { message: string } } };
      console.log(error);
      setCreditSubscriptionsStatusMessage('');
      setPaypalButtonsStatusMessage(
        castError?.response?.data?.message ?? 'Failed to approve transaction'
      );
    }
  };

  const PayPalButtonsProps = {
    forceReRender: [billingFrequency, currency, amountOfCredits],
    ...(options.intent === 'capture'
      ? { createOrder, onApprove: onOrderApprove }
      : { createSubscription, onApprove: onSubscriptionApprove }),
  };

  return (
    <div
      id={`paypal-section-container-${amountOfCredits}`}
      className='max-w-[500px] h-50 overflow-hidden'
      ref={paypalButtonsContainerRef}
    >
      {isPending ? <p>Loading Payment options...</p> : null}
      {paypalButtonsLoadRetries === 0 ? (
        <p>
          Failed to load payment options, please try reloading the page or
          contact us for help.
        </p>
      ) : (
        <PayPalButtons
          {...PayPalButtonsProps}
          onClick={(data: any, actions: any) => {
            console.log('PaypalButtons onClick:', { data, actions });
            fundingSource = data.fundingSource;
          }}
          onError={error => console.log('PaypalButtons error:', { error })}
          onInit={data => console.log('PaypalButtons init:', { data })}
        />
      )}
    </div>
  );
}

function PayPalSection({
  currency,
  amountOfCredits,
}: {
  currency: string;
  amountOfCredits: number;
}) {
  const { billingFrequency, SANDBOX_MODE } = useContext(PagePayContext);

  const initialOptions = {
    'client-id': SANDBOX_MODE
      ? (REACT_APP_PAYPAL_SANDBOX_CLIENT_ID as string)
      : (REACT_APP_PAYPAL_CLIENT_ID as string),
    currency,
    intent: billingFrequency === 'one-time' ? 'capture' : 'subscription',
    vault: 'true', // this property seems to disappear when passed to PaypalScriptProvider
  };

  console.log({ amountOfCredits, initialOptions });
  return (
    <PayPalScriptProvider options={initialOptions}>
      <ButtonWrapper
        currency={currency}
        amountOfCredits={amountOfCredits}
      />
    </PayPalScriptProvider>
  );
}

export default PayPalSection;
