import React, { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { backendURL, cookieDomain } from "../../constants/environmental";
import axios from "axios";
import { Carousel } from "react-responsive-carousel";
import { useAuthHeader } from "react-auth-kit";
import ImageWithFallback from "../../components/ImageWithFallback";
import { Link } from "react-router-dom";
import { futureDateAfterMilliseconds, hoursToMilliseconds } from "../../utils/date";

const RecommendedToolsCarousel = ({ toolId }: { toolId: string }) => {
  const [recommendedTools, setRecommendedTools] = useState<any[] | undefined>();

  const [recommendedToolsViewedStatus, setRecommendedToolsViewedStatus] =
    useState<any>();

  const [recommendedToolsClickedStatus, setRecommendedToolsClickedStatus] =
    useState<any>();

  const [currentRecommendedToolId, setCurrentRecommendedToolId] = useState();
  const [carouselInViewport, setCarouselInViewport] = useState<boolean>(false);
  const [incrementingViews, setIncrementingViews] = useState<boolean>(false);

  const authHeader = useAuthHeader()();

  useEffect(() => {
    loadRecommendedTools();
  }, []);

  useEffect(() => {
    // increment views at most once for each tool per page load
    if (
      !carouselInViewport ||
      currentRecommendedToolId === undefined ||
      recommendedToolsViewedStatus?.[currentRecommendedToolId] ||
      incrementingViews
    ) {
      return;
    }
    setIncrementingViews(true);
    axios
      .patch(
        `${backendURL}/recommendation/${toolId}/${currentRecommendedToolId}/incrementViews`,
        {},
        {
          headers: {
            Authorization: authHeader,
          },
        }
      )
      .then(() => {
        // console.log('incremented views');
        recommendedToolsViewedStatus[currentRecommendedToolId] = true;
      })
      .catch(() => console.log("error incrementing views"))
      .finally(() => setIncrementingViews(false));
  }, [carouselInViewport, currentRecommendedToolId]);

  const carouselRef = useCallback((element: any) => {
    if (!element) return;

    const observer = new IntersectionObserver(([observedElement]) => {
      setCarouselInViewport(observedElement.isIntersecting);
    });

    observer.observe(element);

    // return () => observer.disconnect();
  }, []);

  const [, setCookie] = useCookies(["recommendationSourceToolId"]);

  return !recommendedTools?.length ? null : (
    <>
      <h2>Users Also Liked:</h2>

      <div ref={carouselRef}>
        <Carousel
          autoPlay
          infiniteLoop
          interval={5000}
          onChange={(recommendedToolIndex) => {
            const recommendedToolId =
              recommendedTools[recommendedToolIndex]._id;

            setCurrentRecommendedToolId(recommendedToolId);
          }}
          useKeyboardArrows
          showThumbs={false}
          showIndicators={false}
          showStatus={false}
        >
          {recommendedTools.map((tool) => (
            <div key={tool._id}>
              <ImageWithFallback src={tool.coverPhoto} inContainer={false} />
              <div className="legend">
                <p className="leading-normal">{tool.name}</p>
                <Link
                  reloadDocument
                  className="cursor-pointer underline text-blue-300"
                  to={`/fusion/${tool.customUrlSlug ?? tool._id}`}
                  target="_blank"
                  onClick={onRecommendedToolClick}
                >
                  Open in new tab
                </Link>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </>
  );

  async function onRecommendedToolClick() {
    // increment clicks at most once for each tool per page load
    if (
      !carouselInViewport ||
      !currentRecommendedToolId ||
      recommendedToolsClickedStatus?.[currentRecommendedToolId]
    ) {
      return;
    }
    setCookie("recommendationSourceToolId", toolId, {
      // expires in 1 hour
      expires: futureDateAfterMilliseconds(hoursToMilliseconds(1)),
      path: "/", // make sure the cookie is sent from all paths in the site
      domain: cookieDomain,
    });
    axios
      .patch(
        `${backendURL}/recommendation/${toolId}/${currentRecommendedToolId}/incrementClicks`,
        {},
        {
          headers: {
            Authorization: authHeader,
          },
        }
      )
      .then(() => {
        // console.log('incremented clicks');
        recommendedToolsClickedStatus[currentRecommendedToolId] = true;
      })
      .catch(() => console.log("error incrementing clicks"));
  }

  async function loadRecommendedTools() {
    try {
      const recommendedToolsResponse = await axios.get(
        `${backendURL}/blocks/${toolId}/recommendedTools`,
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );

      setRecommendedTools(recommendedToolsResponse.data);
      setRecommendedToolsViewedStatus(
        Object.fromEntries(
          recommendedToolsResponse.data.map(({ _id }: any) => [_id, false])
        )
      );
      setRecommendedToolsClickedStatus(
        Object.fromEntries(
          recommendedToolsResponse.data.map(({ _id }: any) => [_id, false])
        )
      );
      if (recommendedToolsResponse.data.length === 0) {
        return;
      }
      setCurrentRecommendedToolId(recommendedToolsResponse.data[0]._id);
    } catch (error) {
      console.log("Error loading recommended tools");
    }
  }
};

export default RecommendedToolsCarousel;
