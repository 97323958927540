export function timestampToLocalDate(timestamp: number) {
  return new Date(timestamp).toLocaleString();
}

export function futureDateAfterMilliseconds(milliseconds: number) {
  return new Date(new Date().getTime() + milliseconds);
}

export function daysToMilliseconds(days: number) {
  return days * 24 * 60 * 60 * 1000;
}

export function hoursToMilliseconds(hours: number) {
  return hours * 60 * 60 * 1000;
}