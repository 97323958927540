import React, { useEffect, useState } from "react";
import SectionHowItWork from "../../components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "../../components/BackgroundSection/BackgroundSection";
import SectionBecomeAnAuthor from "../../components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import { Helmet } from "react-helmet";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useAuthHeader, useAuthUser } from "react-auth-kit";
import { Link } from "react-router-dom";
import { batchArray } from "../../utils/array";
import axios from "axios";
import ImageWithFallback from "../../components/ImageWithFallback";
import { backendURL } from "../../constants/environmental";
import PageHomeLoggedOut from "./PageHomeLoggedOut";

function PageHome() {
  const user = useAuthUser()();
  return user ? <PageHomeLoggedIn /> : <PageHomeLoggedOut />;
}

function PageHomeLoggedIn() {
  const [userStart, setUserStart] = useState(0);

  const [usersPurchasedTools, setUsersPurchasedTools] = useState<
    any[] | undefined
  >();
  const [usersCreatedTools, setUsersCreatedTools] = useState<
    any[] | undefined
  >();

  const user = useAuthUser()();
  const authHeader = useAuthHeader()();

  useEffect(() => {
    if (!authHeader) return;

    axios
      .get(`${backendURL}/user/${user?.id}/purchasedToolCarouselPreviews`, {
        headers: { Authorization: authHeader },
      })
      .then(({ data }) => setUsersPurchasedTools(data))
      .catch((error) =>
        console.log("Error fetching users purchased tools:", error)
      );

    axios
      .get(`${backendURL}/user/${user?.id}/createdToolCarouselPreviews`, {
        headers: { Authorization: authHeader },
      })
      .then(({ data }) => setUsersCreatedTools(data))
      .catch((error) =>
        console.log("Error fetching users created tools:", error)
      );
  }, []);

  return (
    <div className="nc-PageHome3 relative overflow-hidden">
      <Helmet>
        <title>Discover the future of AI automation | Skillfusion</title>
        <link rel="canonical" href="https://skillfusion.ai" />
        <meta
          name="description"
          content={
            "AI automation is revolutionising industries with smarter processes, enhancing efficiency, creativity, and decision-making."
          }
        />
      </Helmet>

      <div className="container py-4">
        <h2 className="text-2xl font-bold mb-4 text-blue-400 text-3xl text-center">
          My tools
        </h2>
        {usersPurchasedTools === undefined ? (
          <p>Loading your tools...</p>
        ) : usersPurchasedTools?.length === 0 ? (
          <p className="text-center">{"You don't have any tools yet"}</p>
        ) : (
          <div className="show-arrows">
            <Carousel
              autoPlay
              infiniteLoop
              interval={10000}
              useKeyboardArrows
              showThumbs={false}
              showIndicators={false}
              showStatus={false}
              /* allow touchscreen users to scroll down if the
                           carousel covers the screen */
              preventMovementUntilSwipeScrollTolerance={true}
              swipeScrollTolerance={20}
            >
              {batchArray(4, usersPurchasedTools).map((toolBatch, index) => {
                return (
                  <div
                    className="grid grid-cols-2 justify-evenly lg:grid-cols-4 gap-4"
                    key={index}
                  >
                    {toolBatch.map((tool: any) => {
                      return (
                        <div
                          className="flex flex-col items-center gap-2 carousel-image-container"
                          key={tool._id}
                        >
                          <ImageWithFallback
                            src={tool.coverPhoto}
                            height={48}
                          />
                          <div className="flex flex-col items-center gap-2">
                            <p className="leading-normal text-center">
                              {tool.name}
                            </p>
                            <Link
                              reloadDocument
                              className="cursor-pointer underline text-blue-700"
                              to={`/fusion/${tool.customUrlSlug ?? tool._id}`}
                              target="_blank"
                            >
                              Open in new tab
                            </Link>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </Carousel>
          </div>
        )}
      </div>

      <div className="container py-4">
        <h2 className="text-2xl font-bold mb-4 text-blue-400 text-3xl text-center">
          Tools I Created
        </h2>
        {usersCreatedTools === undefined ? (
          <p>Loading your tools...</p>
        ) : usersCreatedTools?.length === 0 ? (
          <p className="text-center">
            {"You haven't made any tools yet - "}
            <Link
              className="cursor-pointer underline text-blue-700"
              to={`/fuser/0`}
            >
              Tool Maker
            </Link>
            {" | "}
            <Link
              className="cursor-pointer underline text-blue-700"
              to={`/maker-help`}
            >
              Help
            </Link>
          </p>
        ) : (
          <div className="show-arrows">
            <Carousel
              autoPlay
              infiniteLoop
              interval={10000}
              useKeyboardArrows
              showThumbs={false}
              showIndicators={false}
              showStatus={false}
              /* allow touchscreen users to scroll down if the
                           carousel covers the screen */
              preventMovementUntilSwipeScrollTolerance={true}
              swipeScrollTolerance={20}
            >
              {batchArray(4, usersCreatedTools).map((toolBatch, index) => {
                return (
                  <div
                    className="grid grid-cols-2 justify-evenly lg:grid-cols-4 gap-4"
                    key={index}
                  >
                    {toolBatch.map((tool: any) => (
                      <div
                        className="flex flex-col items-center gap-2 carousel-image-container"
                        key={tool._id}
                      >
                        <ImageWithFallback src={tool.coverPhoto} height={48} />
                        <div className="flex flex-col items-center gap-2">
                          <p className="leading-normal text-center">
                            {tool.name}
                          </p>
                          <Link
                            className="cursor-pointer underline text-blue-700"
                            to={`/${tool.coverPhoto ? "fusion" : "fuser"}/${
                              tool.customUrlSlug ?? tool._id
                            }`}
                            target="_blank"
                          >
                            Open in new tab
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                );
              })}
            </Carousel>
          </div>
        )}
      </div>

      <div className="container py-16">
        <h2 className="text-2xl font-bold mb-4 text-blue-400 text-3xl text-center">
          Discover the Future of AI Automation
        </h2>
        <SectionHowItWork />
      </div>

      {/*  <div className="container px-4">*/}
      {/* SECTION HERO */}
      {/*    <SectionHero4 />   */}
      {/* </div>*/}

      {/* Video Section */}
      <div className="container py-20 lg:py-32 px-10 rounded-3xl flex items-center justify-center flex-col">
        <h2 className="text-2xl font-bold mb-4 text-blue-400 text-3xl text-center">
          Every Generative AI Tool you need in one place.
        </h2>
        <div className="video-container w-3/4 rounded-3xl h-96">
          <iframe
            title="YouTube Video"
            className="w-full h-full rounded-3xl"
            src="https://www.youtube.com/embed/tHfV2fiBcuE?si=J1f_ttYoOIhVWFKh"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <p className="text-center text-extrabold mt-4">
          Get access to automated expert skills that you need at an
          unprecedented speed, scale, and cost. These AI skills can be anything
          from drafting legal documents to generating entire books!
        </p>
      </div>

      <div className="container relative space-y-24 mb-24 lg:space-y-32 lg:mb-32">
        {/* SECTION  Top Skill Categories*/}
        {/* <div className='relative py-20 lg:py-28'>
          <BackgroundSection />

          <SectionGridAuthorBox
            sectionStyle='style2'
            userStart={userStart}
            setUserStart={setUserStart}
            boxCard='box4'
          />
        </div> */}

        {/* SECTION 3 */}
        {/* <SectionMagazine8 /> */}

        {/* SECTION 4 */}
        {/* <SectionSliderCardNftVideo /> */}

        {/* SECTION */}
        {/* <div className="relative py-20 lg:py-28">
          <BackgroundSection />
          <SectionSliderCollections />
        </div> */}

        {/* SECTION */}

        {/* <SectionSubscribe2 /> */}

        {/* SECTION */}
        {/* <div className='relative py-20 lg:py-28'>
          <BackgroundSection className='bg-neutral-100/70 dark:bg-black/20  ' />
          <SectionGridFeatureFusion />
        </div> */}

        {/* SECTION 1 */}

        {/* <SectionSliderCategories /> */}

        {/* SECTION */}
        <div className="relative py-20 lg:py-24">
          <BackgroundSection />
          <SectionBecomeAnAuthor />
        </div>
      </div>
    </div>
  );
}

export default PageHome;
