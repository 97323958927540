import React, { FC, useRef, useState } from 'react';
// import facebookSvg from 'images/Facebook.svg';
// import twitterSvg from "images/Twitter.svg";
// import googleSvg from 'images/Google.svg';
import { Helmet } from 'react-helmet';
import Input from '../../shared/Input/Input';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ButtonPrimary from '../../shared/Button/ButtonPrimary';
// import { GoogleAuthProvider , FacebookAuthProvider , TwitterAuthProvider} from 'firebase/auth';
import axios from 'axios';
import ErrorPopup from '../../components/ErrorPopup';
import { useSignIn, useAuthUser } from 'react-auth-kit';
import ReactGA from 'react-ga4';
import { useCredit } from '../../context/CreditContext';
import { useCookies } from 'react-cookie';
import { backendURL, cookieDomain } from '../../constants/environmental';
import { daysToMilliseconds, futureDateAfterMilliseconds } from '../../utils/date';
export interface PageLoginProps {
  className?: string;
  tempModalID?: string;
  message?: string;
}

const VerifyEmail: FC<PageLoginProps> = ({
  className = '',
  tempModalID = '',
  message = '',
}) => {
  const { credit, updateCredits } = useCredit();
  const location = useLocation();
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
  const codeRef = useRef<HTMLInputElement>(null);
  //const passwordRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const auth = useAuthUser();
  const user = auth();
  const signIn = useSignIn();
  // console.log(user);
  const [error, setError] = useState<string | null>(null);
  const handleCloseError = () => {
    console.log('close error');
    setError(null);
  };
  const [, setCookie, removeCookie] = useCookies([
    'accountId',
    'l_id',
    'referrerId',
    'accountCreatedWithNoFreeCreditsAndNeverBoughtCredits'
  ]);

  const Signin = async () => {
    setIsLoggingIn(true);
    const tempCode = codeRef.current!.value;
    //const password = passwordRef.current!.value;
    let loggedInUserId; // Variable to store the logged-in user's ID

    const path = location.pathname;
    const pathParts = path.split('/');
    const lastSegment = pathParts.pop() || pathParts.pop(); // handle potential trailing slash
    let tempID = '';
    if (lastSegment !== '0' && lastSegment !== undefined) tempID = lastSegment;
    if (tempModalID != '') tempID = tempModalID;

    try {
      const res = await axios.post(
        `${backendURL}/user/templogin`,
        {
          tempID,
          tempCode,
        },
        { withCredentials: true }
      );
      loggedInUserId = res.data.userId;
      setCookie('accountId', loggedInUserId, {
        domain: cookieDomain,
        path: '/',
        expires: futureDateAfterMilliseconds(daysToMilliseconds(7)),
      });
      setCookie('l_id', res.data.userId, {
        domain: cookieDomain,
        path: '/',
        expires: futureDateAfterMilliseconds(daysToMilliseconds(1)),
      });
      if (res.data.accountCreatedWithNoFreeCreditsAndNeverBoughtCredits) {
        setCookie(
          'accountCreatedWithNoFreeCreditsAndNeverBoughtCredits',
          true,
          {
            domain: cookieDomain,
            path: "/",
            expires: futureDateAfterMilliseconds(daysToMilliseconds(30)),
          }
        )
      }
      removeCookie('referrerId', {
        domain: cookieDomain,
        path: '/'
      });

      if (
        signIn({
          token: res.data.token,
          expiresIn: res.data.expirationTimeInMinutes,
          tokenType: 'Bearer',
          authState: res.data.authUserState,
        })
      ) {
        //setUser(res.data.authUserState);
        //console.log(res.data.token)
        //console.log(res.data);
        console.log('signed in');
      }

      // try and get credits
      try {
        const response = await axios.post(
          `${backendURL}/user/checkCredits`,
          {},
          {
            headers: {
              Authorization: `Bearer ${res.data.token}`,
            },
          }
        );

        if (response.status === 200) {
          const dollarsToCredits = response.data * 10;
          updateCredits(dollarsToCredits);
          //localStorage.setItem('credit',dollarsToCredits.toString()); // to get nav to rerender correct credit
          //console.log("got credits during login",dollarsToCredits);
        }
      } catch (error) {
        // Handle error
        console.error(error);
      }
    } catch (registerError: any) {
      console.error('Error creating account', registerError);
      console.log(registerError);

      setError(`${registerError.response.data.error}`);
      // track failed login
      ReactGA.event('failed_login_attempt');
    } finally {
      setIsLoggingIn(false);
      if (loggedInUserId) {
        // track failed login
        ReactGA.event('successful_login');

        if (!tempModalID) {
          navigate(`/page-search`, {
            state: {
              emailVerifiedMessage:
                'Welcome to Skillfusion! Your email has been verified and you are now signed in.',
            },
          });
        }
      }
    }
  };

  return (
    <div
      className={`nc-PageLogin ${className}`}
      data-nc-id='PageLogin'
    >
      {!user || user.trialAccount ? (
        <>
          <Helmet>
            <title>Email Verification</title>
          </Helmet>

          <div className='container mb-24 lg:mb-32'>
            <h2 className='my-7 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center'>
              Enter Email Verification Code
            </h2>
            {/* <h3 className='my-7 flex items-center text-xl justify-center'>
              To Get Your Free Credits
            </h3> */}

            <div className='max-w-md mx-auto space-y-6 flex flex-col items-center'>
              <form
                className='grid grid-cols-1 gap-6'
                onSubmit={e => {
                  e.preventDefault();
                  Signin();
                }}
              >
                {location?.state?.verifyEmailMessage && (
                  <p className=''>{location.state.verifyEmailMessage}</p>
                )}
                {message && <p className=''>{message}</p>}
                <label className='block'>
                  <span className='text-neutral-800 dark:text-neutral-200'>
                    6 Digit Code:
                  </span>
                  <Input
                    type='text'
                    placeholder='123456'
                    className='mt-1'
                    ref={codeRef}
                  />
                </label>

                {error && (
                  <ErrorPopup
                    message={error}
                    onClose={handleCloseError}
                  />
                )}
              </form>

              <div className='flex justify-center'>
                <ButtonPrimary
                  onClick={Signin}
                  className='justify-self-center'
                >
                  {isLoggingIn ? 'Verifying email...' : 'Verify email'}
                </ButtonPrimary>
              </div>

              <Link
                to='/signup'
                className='block text-center text-neutral-700 dark:text-neutral-300 hover:scale-110 font-bold text-lg'
              >
                New user? {` `}
                <span className='text-green-600'>Create an account</span>
              </Link>
            </div>
          </div>
        </>
      ) : (
        <div className='container flex items-center flex-col justify-center gap-4 m-2'>
          {tempModalID ? (
            <h2 className='font-bold'>
              {
                'Your email has been verified - thank you for signing up!'
              }
            </h2>
          ) : (
            <>
              <h2>
                <span>You are currently signed in as: {user?.username}</span>
              </h2>
              <ButtonPrimary onClick={() => navigate(`/profile/${user?.id}`)}>
                My Profile
              </ButtonPrimary>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default VerifyEmail;
