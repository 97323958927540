import React, { FC, forwardRef } from 'react';
import { Disclosure } from '@headlessui/react';

const DisclosurePanelWithRef: FC<any> = forwardRef<any, any>(
  ({ html, className, unmount }, ref) => (
    <Disclosure.Panel
      className={className}
      as='div'
      unmount={unmount}
      dangerouslySetInnerHTML={{ __html: html }}
      ref={ref}
    ></Disclosure.Panel>
  )
);

DisclosurePanelWithRef.displayName = 'DisclosurePanelWithRef';

export default DisclosurePanelWithRef;
